import React, { useState, useEffect } from "react";
import "./styles/globals/global.scss";
import "bulma/css/bulma.min.css";
import MainForm from "./components/MainForm";
import RegistrationForm from "./components/RegistrationForm";
import DetailsForm from "./components/DetailsForm";
import FinalScreen from "./components/FinalScreen";
import tagsData from "./data/pi_site_tags.json";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";

function App() {
  const [details, setDetails] = useState(true);
  const [main, setMain] = useState(false);
  const [registration, setRegistration] = useState(false);
  const [finish, setFinish] = useState(false);

  const [selectedSite, setSelectedSite] = useState("aberdare");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [activityDetails, setActivityDetails] = useState(
    "This May, I'm taking part in our Million Minutes month where collectively we will pledge 1,000,000 minutes of fundraising for GOSH Charity. Please support me in helping raise funds for the Children's Cancer Centre."
  );
  const [campaignTitle, setCampaignTitle] = useState("");
  const [target, setTarget] = useState("");
  const [mins, setMins] = useState(60);
  const [otherMins, setOtherMins] = useState("");
  const [tags, setTags] = useState(tagsData);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [btnTxt, setBtnTxt] = useState("NEXT STEP");
  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const [isEmpty, setIsEmpty] = useState(false);
  const [passwordConfirmed, setIsPasswordConfirmed] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);

  //const [returnedData, setReturnedData] = useState({});
  const [returnedData, setReturnedData] = useState(null);
  const [returnedError, setReturnedError] = useState("");

  const userObj = {
    eventId: "5ccb9fa2-4c73-46cb-a83c-65d4674d0931",
    assistanceNotes: "",
    campaignSetup: {
      targetAmount: 0,
      currencyId: "9d6494c0-e364-43f0-b76c-52251df71133",
      campaignName: "",
      campaignDescription:
        "This May, I'm taking part in our Million Minutes month where collectively we will pledge 1,000,000 minutes of fundraising for GOSH Charity. Please support me in helping raise funds for the Children's Cancer Centre.",
      campaignSummary: "",
      tags: {
        pledged_minutes: "60",
      },
    },
    participants: [
      {
        firstName: "",
        lastName: "",
        title: "",
        email: "",
        primaryPartcipant: true,
        createAccount: true,
        password: "",
        tags: {
          site: "",
          division: "",
          area: "",
        },
      },
    ],
  };

  const [user, setUser] = useState(userObj);

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validatePassword = (password) => {
    const passwordPattern =
      /^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z0-9])|(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])|(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])).{6,}$/;
    return passwordPattern.test(password);
  };

  const finalStep = async (user) => {
    const options = {
      method: "POST",
      url: "https://api.givestar.io/api/v1/S2S/Events/Signup",
      headers: {
        "X-GS2SAPI-Key": "rZBSdkg3L06WJs@",
        "X-GS2SAPI-OID": "775d0a8a-1c74-4dcf-b061-06bf90e949f0",
        "Content-Type": "application/json",
      },
      data: user,
    };

    axios(options)
      .then(function (response) {
        if (response.status === 200) {
          setReturnedData(response.data);
        }
      })
      .catch(function (error) {
        setReturnedError(error);
        console.log("Error fetching data:", error);
      });
  };

  useEffect(() => {
    if (checkbox1 && checkbox2) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [checkbox1, checkbox2, details]);

  useEffect(() => {
    if (password === confirmPassword && validatePassword(password)) {
      setIsPasswordConfirmed(true);
      setErrorMessage("");
      setIsError(false);
    }
  }, [password, confirmPassword]);

  const changePage = async () => {
    if (details) {
      if (email === "") {
        setIsEmpty(true);
      } else {
        if (!validateEmail(email)) {
          setIsError(true);
          setErrorMessage("Please enter a valid email address!");
        } else {
          setBtnTxt("CREATE ACCOUNT");

          setIsError(false);
          setErrorMessage("");

          const addTags = tags.filter((item) => item.siteId === selectedSite);
          
          const newUser = {
            ...user,
            participants: user.participants.map((participant) => ({
              ...participant,
              email: email,
              tags: addTags[0].tags,
            })),
          };

          setUser(newUser);

          setIsEmpty(false);
          setDetails(false);
          setMain(true);
        }
      }
    } else if (main) {
      if (
        firstName === "" ||
        lastName === "" ||
        password === "" ||
        confirmPassword === ""
      ) {
        setIsEmpty(true);
      } else {
        if (password !== confirmPassword) {
          setIsPasswordConfirmed(false);
          setIsError(true);
          setErrorMessage("Passwords do not match!");
        } else {
          if (!validatePassword(password)) {
            setIsError(true);
            setErrorMessage(
              "Passwords must be at least 6 characters and contain at 3 of 4 of the following: upper case (A-Z), lower case (a-z), number (0-9) and special character (e.g. !@#$%^&*)"
            );
          } else {
            setIsPasswordConfirmed(true);
            setIsError(false);
            setErrorMessage("");
            const newUser = {
              ...user,
              participants: user.participants.map((participant) => ({
                ...participant,
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                password: password.trim(),
              })),
            };

            setBtnTxt("REGISTER");
            setUser(newUser);
            setIsEmpty(false);
            setMain(false);
            setRegistration(true);
          }
        }
      }
    } else if (registration) {
      if (activityDetails === "" || target === "") {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);

        setUser({
          ...user,
          campaignSetup: {
            ...user.campaignSetup,
            targetAmount: target,
            campaignName: campaignTitle,
            campaignDescription: activityDetails,
            tags: {
              ...user.campaignSetup.tags,
              pledged_minutes:
                otherMins !== "" ? String(otherMins) : String(mins),
            },
          },
        });

        finalStep({
          ...user,
          campaignSetup: {
            ...user.campaignSetup,
            targetAmount: target,
            campaignName: campaignTitle,
            campaignDescription: activityDetails,
            tags: {
              ...user.campaignSetup.tags,
              pledged_minutes:
                otherMins !== "" ? String(otherMins) : String(mins),
            },
          },
        });
        setRegistration(false);
        setFinish(true);
      }
    }
  };

  const goBack = () => {
    if (main) {
      setDetails(true);
      setMain(false);
      setBtnTxt("NEXT STEP");
      setIsError(false);
      setErrorMessage("");
    } else if (registration) {
      setMain(true);
      setRegistration(false);
      setBtnTxt("CREATE ACCOUNT");
    }
  };

  return (
    <div>
      {main && (
        <div className="hideScreen">
          <img src="../images/logomob.jpeg" alt="Logo" />
        </div>
      )}
      <div
        className={`background`}
        style={{
          backgroundImage: "url(../images/logo.png)",
        }}
      >
        <div className="containerWrapper" style={{ position: "relative" }}>
          {(main || registration) && (
            <div className="backBtn" onClick={() => goBack()}>
              <ArrowBackIcon />
            </div>
          )}

          <div className={`mainContainer ${details ? "" : "bgMobile"}`}>
            <div className="mob-container">
              {(main || registration) && (
                <div className="progress-container">
                  <div className="step">
                    <div className="circle filledCircle"></div>
                  </div>
                  <div className="line"></div>
                  <div className="step">
                    <div
                      className={`circle ${registration ? "filledCircle" : ""}`}
                    ></div>
                  </div>
                </div>
              )}

              {details && (
                <div>
                  <MainForm
                    selectedSite={selectedSite}
                    setSelectedSite={setSelectedSite}
                    email={email}
                    setEmail={setEmail}
                    isEmpty={isEmpty}
                    checkbox1={checkbox1}
                    checkbox2={checkbox2}
                    setCheckbox1={setCheckbox1}
                    setCheckbox2={setCheckbox2}
                  />
                </div>
              )}
              {main && (
                <div>
                  <RegistrationForm
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    password={password}
                    confirmPassword={confirmPassword}
                    setPassword={setPassword}
                    setConfirmPassword={setConfirmPassword}
                    isEmpty={isEmpty}
                  />
                </div>
              )}
              {registration && (
                <div>
                  <DetailsForm
                    activityDetails={activityDetails}
                    setActivityDetails={setActivityDetails}
                    target={target}
                    setTarget={setTarget}
                    mins={mins}
                    setMins={setMins}
                    otherMins={otherMins}
                    setOtherMins={setOtherMins}
                    isEmpty={isEmpty}
                    campaignTitle={campaignTitle}
                    setCampaignTitle={setCampaignTitle}
                  />
                </div>
              )}
              {finish && (
                <FinalScreen
                  returnedData={returnedData}
                  returnedError={returnedError}
                />
              )}
            </div>
            {isError && (!passwordConfirmed || !isValidEmail) && (
              <div
                className="notification is-danger is-light"
                style={{ textAlign: "center" }}
              >
                {errorMessage}
              </div>
            )}
            {!finish && (
              <button
                className="width100 button is-outlined is-uppercase buttonCustom has-text-weight-medium"
                onClick={() => changePage()}
                disabled={btnDisabled}
              >
                {btnTxt}
              </button>
            )}
            {main && (
              <p
                className="accountTtx mt-3"
                onClick={() => {
                  setRegistration(true);
                  setMain(false);
                  setBtnTxt("REGISTER");
                }}
              >
                I already have a givestar account
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
