import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-calendar/dist/Calendar.css";
import RadioBtns from "./RadioBtns";

function DetailsForm({
  activityDetails,
  setActivityDetails,
  target,
  setTarget,
  mins,
  setMins,
  otherMins,
  setOtherMins,
  isEmpty,
  campaignTitle,
  setCampaignTitle,
}) {

  const handleOtherMinsChange = (e) => {
    setMins(0)
    let value = e.target.value;
    if (value.length > 4) {
      value = value.slice(0, 4);
    }
    setOtherMins(value);
  };

  return (
    <div>
      <div>
        <p className="subheader">TELL US YOUR FUNDRAISING GOALS</p>
        <p className="text-body">
          We would love to hear all about your fundraising plans below.
        </p>
      </div>
      <div>
        <p className="subheader2 mt-4">MILLION MINUTES ACTIVITY</p>
        <p className="text-body2 mt-3">Give your page a title</p>
      </div>
      <div>
        <div className="mb-4">
          <input
            className={`input inputStyle mt-3 ${
              isEmpty && campaignTitle === "" ? "emptyInput" : ""
            }`}
            rows="5"
            placeholder="My fundraising page title..."
            value={campaignTitle}
            onChange={(e) => setCampaignTitle(e.target.value)}
          ></input>
        </div>
      </div>
      <div>
        <p className="text-body2">
          Tell us briefly what you’re planning for your Million Minutes activity
        </p>
        <div className="mb-4">
          <textarea
            className={`input inputStyle2 mt-3 textareaStyle ${
              activityDetails ? "normalText" : "emptyText"
            } ${isEmpty && activityDetails === "" ? "emptyInput" : ""}`}
            rows="5"
            placeholder="Tell everyone what you are aiming to do..."
            value={activityDetails}
            onChange={(e) => setActivityDetails(e.target.value)}
          ></textarea>
        </div>
        <div>
          <p className="subheader2 mt-4">SET FUNDRAISING TARGET</p>
          <p className="text-body2">How much are you hoping to raise?</p>
        </div>
        <div className="field mb-4 mt-3">
          <p className="control has-icons-left">
            <input
              className={`input inputStyle ${
                isEmpty && target === "" ? "emptyInput" : ""
              }`}
              type="number"
              placeholder="25"
              value={target}
              onChange={(e) => setTarget(e.target.value)}
            ></input>
            <span
              className="icon is-small is-left"
              style={{ color: "#002c77", size: "24px", lineHeight: "36px" }}
            >
              £
            </span>
          </p>
        </div>
        <div className="mb-2">
          <p className="subheader2">SET MINUTES PLEDGED</p>
          <p className="text-body2">
            This is your share of the 1,000,000 minutes pledge.
          </p>
        </div>
        <RadioBtns mins={mins} setMins={setMins} setOtherMins={setOtherMins} />
        <div className="field mt-3 mb-4">
          <p className="control has-icons-right">
            <input
              className="input inputStyle"
              type="number"
              placeholder="Other"
              value={otherMins}
              onChange={handleOtherMinsChange}
            ></input>
            <span className="icon is-small is-right">
              <span className="mins-txt pr-6">mins</span>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default DetailsForm;
