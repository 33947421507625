import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-calendar/dist/Calendar.css";
import dropdown_data from "../data/pi_site_select.json";

function MainForm({
  selectedSite,
  setSelectedSite,
  email,
  setEmail,
  isEmpty,
  checkbox1,
  checkbox2,
  setCheckbox1,
  setCheckbox2,
}) {
  const [data, setData] = useState(dropdown_data);

  return (
    <div>
      <div>
        <p className="titleMain">REGISTER TO TAKE PART IN MILLION MINUTES</p>
        <p className="text-body">
          To register for Million Minutes, please provide the information below
          using your <span className="has-text-weight-bold">named email</span>{" "}
          (please do not use your site email). Registering will create your
          account on the givestar app.
        </p>
      </div>
      <div className="mt-6 mb-6">
        <div className="mb-4">
          <p className="form-label">EMAIL</p>
          <input
            className={`input inputStyle ${
              isEmpty && email === "" ? "emptyInput" : ""
            }`}
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
        </div>
        <div className="mb-4 flexOne">
          <p className="form-label">SELECT YOUR SITE</p>
          <p className="text-body2 mb-2">If you work at Support Centre, please type your Exco Lead's name below:</p>
          <div className="field width100">
            <p className="control width100">
              <span className="select width100">
                <select
                  className="control width100 selectStyle"
                  value={selectedSite}
                  onChange={(e) => setSelectedSite(e.target.value)}
                >
                  {data.map((item, i) => {
                   
                    return (
                      <option key={i} value={item.siteid}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </span>
            </p>
          </div>
        </div>
        <div>
          <div className="mb-3">
            <input
              type="checkbox"
              id="checkbox1"
              name="checkbox1"
              checked={checkbox1}
              onChange={() => setCheckbox1(!checkbox1)}
            />
            <label className="ml-2 termsTxt" for="checkbox1">
              I agree to givestar’s{" "}
              <a
                href="https://givestar.io/terms-and-conditions"
                target="_blank"
                rel="noreferrer"
                className="termsTxtLink"
              >
                Terms of Use
              </a>{" "}
              and{" "}
              <a
                href="https://givestar.io/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className="termsTxtLink"
              >
                Privacy Policy
              </a>
              .
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              id="checkbox2"
              name="checkbox2"
              checked={checkbox2}
              onChange={() => setCheckbox2(!checkbox2)}
            />
            <label className="ml-2 termsTxt" for="checkbox2">
              I understand a summary of my fundraising page, including my name
              and the total funds raised, is shared with GOSH Charity, who share
              this information with Whitbread Group Plc. For more information on
              how GOSH Charity uses your information, please see
              <span>
                <a
                  href="https://gosh.org/privacy-policy-statement/"
                  target="_blank"
                  rel="noreferrer"
                  className="termsTxtLink ml-1"
                >
                  https://gosh.org/privacy-policy-statement/
                </a>
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainForm;
