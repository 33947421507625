import React, { useState, useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-calendar/dist/Calendar.css";

function RegistrationForm({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  password,
  confirmPassword,
  setPassword,
  setConfirmPassword,
  isEmpty,
}) {
  return (
    <div>
      <div>
        <p className="subheader">CREATE YOUR GIVESTAR ACCOUNT</p>
        <p className="text-body">
          Fill out the form below to get your account set up on the givestar
          app, ready for you to start fundraising for Great Ormond Street
          Children’s Charity.
        </p>
      </div>
      <div className="mt-6 mb-6">
        <div className="mb-4">
          <p className="form-label">FIRST NAME</p>
          <input
            className={`input ${
              isEmpty && firstName === "" ? "emptyInput" : ""
            }`}
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          ></input>
        </div>
        <div className="mb-4">
          <p className="form-label">LAST NAME</p>
          <input
            className={`input inputStyle ${
              isEmpty && lastName === "" ? "emptyInput" : ""
            }`}
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          ></input>
        </div>
        <div className="mb-2">
          <p className="form-label">PASSWORD</p>
          <input
            className={`input inputStyle ${
              isEmpty && password === "" ? "emptyInput" : ""
            }`}
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></input>
        </div>
        <p
          className="mb-3"
          style={{ fontSize: "13px", lineHeight: "14px", color: "#002C77" }}
        >
          Passwords must be at least{" "}
          <span className="has-text-weight-bold">6 characters</span> long and
          contain <span className="has-text-weight-bold">1 lowercase</span>,{" "}
          <span className="has-text-weight-bold">1 uppercase</span>,{" "}
          <span className="has-text-weight-bold">1 number</span> and a{" "}
          <span className="has-text-weight-bold">special character</span>.
        </p>
        <div className="mb-1">
          <p className="form-label">CONFIRM PASSWORD</p>
          <input
            className={`input inputStyle ${
              isEmpty && confirmPassword === "" ? "emptyInput" : ""
            }`}
            type="password"
            placeholder="Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></input>
        </div>
      </div>
    </div>
  );
}

export default RegistrationForm;
