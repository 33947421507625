import React from "react";

function RadioBtns({ mins, setMins, setOtherMins }) {
  return (
    <div className="control is-flex has-text-dark" style={{ gap: "10px" }}>
      <div
        className={`minsBtn ${mins === 25 ? "minBtnSelected" : ""}`}
        onClick={() => {setMins(25); setOtherMins('')}}
      >
        25 mins
      </div>
      <div
        className={`minsBtn ${mins === 60 ? "minBtnSelected" : ""}`}
        onClick={() => {setMins(60); setOtherMins('')}}
      >
        <p>60 mins</p>
        <p className="text-body-xs">most pledged</p>
      </div>
      <div
        className={`minsBtn ${mins === 90 ? "minBtnSelected" : ""}`}
        onClick={() => {setMins(90); setOtherMins('')}}
      >
        90 mins
      </div>
      <div
        className={`minsBtn ${mins === 120 ? "minBtnSelected" : ""}`}
        onClick={() => {setMins(120); setOtherMins('')}}
      >
        120 mins
      </div>
    </div>
  );
}

export default RadioBtns;
