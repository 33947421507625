import React from "react";

function FinalScreen({ returnedData, returnedError }) {
  
  return (
    <div>
      {!returnedData && !returnedError && (
        <div className="box-body finalPageContainer has-text-grey-darker">
          <p className="subheader3 mb-3">SETTING YOUR PAGE UP...</p>
          <p style={{ color: "#00286b", textAlign: "center" }}>
            We're just creating your page on givestar{" "}
          </p>
        </div>
      )}

      {!returnedData && returnedError && (
        <div>
          <div>
            <p className="subheader3Error has-text-weight-bold">
              OOPS! SOMETHING WENT WRONG
            </p>
          </div>
          <div className="mt-3">
            <p
              className="form-label"
              style={{ textAlign: "center", fontSize: "14px" }}
            >
              Looks like something went wrong during registration.{" "}
              {returnedError && (
                <span style={{ textAlign: "center" }}>
                  {returnedError.response.data}
                </span>
              )}
            </p>
          </div>
        </div>
      )}

      {returnedData && (
        <div>
          <div className="box-body finalPageContainer has-text-grey-darker">
            <p className="subheader3 mb-3">YOU'RE GOOD TO GO! &#x1F389;</p>
            <p className="mainsubheader_final" style={{ color: "#00286b" }}>
              Your fundraising page is ready to go on givestar!{" "}
              <span className="has-text-weight-bold">
                Download the givestar app
              </span>{" "}
              and proceed to{" "}
              <span className="has-text-weight-bold">log in</span> with the{" "}
              <span className="has-text-weight-bold">same email</span> and{" "}
              <span className="has-text-weight-bold">password</span> you have
              used for sign up.
            </p>
            {/* <div
              className="mt-3 mb-2 form-label"
              style={{ textAlign: "center" }}
            >
              <a
                href={returnedData.data.campaignLink}
                target="_blank"
                rel="noreferrer"
              >
                <p className="has-text-weight-bold viewCampaign mt-3">
                  Go to my page
                </p>
              </a>
            </div> */}
            <div className="finalContainer mt-6">
              <img
                src={"../../images/phone-img.png"}
                alt="Phone"
                className="phone-img phone_desk"
              />
              <p className="finalSubheader">DOWNLOAD THE GIVESTAR APP</p>
              <p className="finalTxt mt-3">Click on the relevant link below</p>
              <div className="is-flex containerFinal">
                <div
                  className="is-clickable"
                  style={{ width: "36%", marginTop: "30px" }}
                >
                  <a
                    href="https://apps.apple.com/gb/app/givestar/id1628102747"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="../../images/apple-store.png" alt="Apple Store" />
                  </a>
                </div>
                <div
                  className="is-clickable"
                  style={{
                    width: "40%",
                    marginTop: "30px",
                    marginLeft: "20px",
                  }}
                >
                  <a
                    href="https://play.google.com/store/apps/details?id=com.givestarapp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={"../../images/google-play.png"}
                      alt="Google Play"
                    />
                  </a>
                </div>
              </div>
              <img
                src={"../../images/phone-img.png"}
                alt="Phone"
                className="phone-img mt-6 phone_mob"
              />
            </div>
          </div>
          <div className="mt-6">
            <a
              href={returnedData.data.campaignLink}
              target="_blank"
              rel="noreferrer"
            >
              <p
                style={{
                  textAlign: "center",
                  fontSize: "13px",
                  color: "#B3B3B3",
                  textDecoration: "underline",
                }}
              >
                View fundraising page on browser
              </p>
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default FinalScreen;
